
import Select from '@/components/Select.vue';
import { defineComponent, PropType } from 'vue';
import { MemberListParams } from './index.vue';

export default defineComponent({
  name: 'ListFilter',
  components: {
    Select,
  },
  props: {
    params: {
      type: Object as PropType<MemberListParams>,
      required: true,
    },
    handleChangeFilterParams: { type: Function, required: true },
    onClickApply: { type: Function, required: true },
    onClickSearch: { type: Function, required: true },
    isFilterOn: Boolean,
  },
  methods: {},
  setup(props) {
    const memberTypeOptions = [
      { value: '', label: '전체' },
      { value: 'MEMBER', label: '일반회원' },
      { value: 'PARTNER', label: '파트너회원' },
    ];
    const updateParams = (key: string, value: any) => {
      console.log('updateParams', key, value);
      props.handleChangeFilterParams(key, value);
    };

    return {
      updateParams,
      memberTypeOptions,
    };
  },
});

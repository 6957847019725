<template>
  <table
    v-if="members"
    class="ListTable table-fixed w-full text-center text-sm mt-6"
  >
    <thead class="bg-gray-200">
      <tr class="h-10">
        <th class="w-15 border-t border-black border-b">member #</th>
        <th class="w-20 border-t border-black border-b">파트너/일반 구분</th>
        <th class="w-10 border-t border-black border-b">회원명</th>
        <th class="w-24 border-t border-black border-b">파트너 회사명</th>
        <th class="w-30 border-t border-black border-b">회원ID(이메일)</th>
        <th class="w-20 border-t border-black border-b">휴대전화</th>
        <th class="w-10 border-t border-black border-b">생년월일</th>
        <th class="w-10 border-t border-black border-b">성별</th>
        <th class="w-40 border-t border-black border-b">주소</th>
        <th class="w-20 border-t border-black border-b">가입일</th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="border-b border-grey-300 text-center cursor-pointer"
        v-for="(item, index) in members"
        @click="handleClickRow(item)"
        :key="index"
      >
        <!-- memberId -->
        <td>
          <div class="ml-4">{{ item.memberId }}</div>
        </td>

        <!-- 회원타입 -->
        <td>
          <div>{{ item.memberType === 'MEMBER' ? '일반회원' : item.approved ? '파트너회원' : '파트너(승인대기)' }}</div>
        </td>

        <!-- 회원명 -->
        <td>
          {{ item.name || '-' }}
        </td>

        <!-- 파트너 회사명 -->
        <td>
          {{ item.companyName || '-' }}
        </td>

        <!-- 회원ID(이메일) -->
        <td>
          {{ item.email }}
        </td>

        <!-- 휴대전화 -->
        <td>
          {{ item.mobile?.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-") || '-' }}
        </td>

        <!-- 생년월일-->
        <td>
          {{ item.birthday || '-'}}
        </td>

        <!-- 성별 -->
        <td>
          {{ item.sex || '-'}}
        </td>

        <!-- 주소 -->
        <td>
          {{ item.address || '-'}}
        </td>

        <!-- 가입일 -->
        <td>
          {{ format(new Date(item.createdAt), 'yyyy/MM/dd HH:mm') || '-'}}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, onUpdated, PropType, ref } from 'vue';
import { format } from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';

export default defineComponent({
  name: 'ListTable',
  components: {
  },
  props: {
    members: Object as PropType<any>,
  },
  setup: function (props, { emit }) {
    const { categoryDepth1Obj } = useCategoryOptions();

    onUpdated(() => {
      console.log('members', props.members);
    });


    const handleClickRow = (item) => {
      // 상품상세로 이동
      router.push({
        path: `/app/member/detail/${item.memberId}`,
      });
    };


    return {
      format,
      handleClickRow,
    };
  },
});
</script>

<style lang="scss" scoped>
.ListTable {
  tbody {
    tr {
    }
  }

  td {
    padding: 8px 10px;
  }
}
</style>

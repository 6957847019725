
import { defineComponent, onUpdated, PropType, ref } from 'vue';
import { format } from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';

export default defineComponent({
  name: 'ListTable',
  components: {
  },
  props: {
    members: Object as PropType<any>,
  },
  setup: function (props, { emit }) {
    const { categoryDepth1Obj } = useCategoryOptions();

    onUpdated(() => {
      console.log('members', props.members);
    });


    const handleClickRow = (item) => {
      // 상품상세로 이동
      router.push({
        path: `/app/member/detail/${item.memberId}`,
      });
    };


    return {
      format,
      handleClickRow,
    };
  },
});

<template>
  <Container>
    <!-- Filter -->
    <ListFilter
      :params="filterParams"
      :handleChangeFilterParams="handleChangeFilterParams"
      :onClickApply="toggleApplyFilters"
      :onClickSearch="handleClickSearch"
      :isFilterOn="isFilterOn"
    />

    <ListTable
      :members="memberList.data"
      @refresh="fetchMemberList"
    ></ListTable>

    <div class="mt-10 flex">
      <button
        class="inline-flex items-center justify-center h-8 w-32 text-xs mr-5 excel-download"
        @click="handleExcelDownload"
      >
        <ExcelDownIcon class="mr-2" />
        엑셀다운로드
      </button>
      <Pagination
        :initialPage="filterParams.page"
        :pageSize="parseInt(filterParams.pageSize)"
        :onChange="handleChangePage"
        :onChangePageSize="handleChangePageSize"
        :total="memberList.total"
      ></Pagination>
    </div>
  </Container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, reactive, toRef } from 'vue';
import { useRoute } from 'vue-router';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import ListFilter from './ListFilter.vue';
import ListTable from './ListTable.vue';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format/index.js';
import datePattern from '@/constants/datePattern';
import ExcelDownIcon from '@/components/icons/ExcelDownIcon.vue';
import _ from 'lodash'
import XLSX from 'xlsx'
import getServerErrorMessage from "@/utils/getServerErrorMessage";

export type MemberListParams = {
  start?: string;
  end?: string;
  memberType?: any;
  approved?: any;
  keyword?: string;
  page: number;
  pageSize: number;
};

export default defineComponent({
  name: 'MemberList',
  components: {
    Container,
    ListFilter,
    ListTable,
    Pagination,
    ExcelDownIcon
  },
  props: {},
  setup() {
    const route = useRoute();
    const query = route.query;

    const filterParams = reactive<MemberListParams>({
      start: (query.start as string) || '',
      end: (query.end as string) || '',
      memberType: (query.memberType as string) || '',
      approved: (query.approved) || undefined,
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
    });

    const isFilterOn = ref(query.isFilter === 'true');

    const memberList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    });

    const toggleApplyFilters = () => {
      isFilterOn.value = !isFilterOn.value;
      executeSearch();
    };

    const handleChangeFilterParams = (key: string, value: any) => {
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };
    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };
    const handleClickSearch = () => {
      console.log('베르나');
      filterParams.page = 0;
      executeSearch();
    };

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      //fetchMemberList();
      router.push({
        path: window.location.pathname,
        query: {
          start: filterParams.start || '',
          end: filterParams.end || '',
          memberType: filterParams.memberType || '',
          approved: filterParams.approved || undefined,
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
          isFilter: String(isFilterOn.value),
        },
      });
    };

    const getAppliedFilterParams = () =>
      isFilterOn.value
        ? {
            ...filterParams,
            start: filterParams.start
              ? format(
                  new Date(filterParams.start),
                  datePattern.INPUT_DATE + ' 00:00:00'
                )
              : undefined,
            end: filterParams.end
              ? format(
                  new Date(filterParams.end),
                  datePattern.INPUT_DATE + ' 23:59:59'
                )
              : undefined,
          }
        : {
            // 필터가 적용되어있지 않으면 타이틀만 사용한다
            keyword: filterParams.keyword,
            page: filterParams.page,
            pageSize: filterParams.pageSize,
          };

    const fetchMemberList = async () => {
      console.group('fetchMemberList');
      console.log('finalFilterParams', getAppliedFilterParams());
      console.groupEnd();

      try {
        const { data } = await partnerAPI.adminMember.adminMemberList(
          getAppliedFilterParams()
        );
        memberList.value = data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };

    const startRef = toRef(filterParams, 'start');
    const endRef = toRef(filterParams, 'end');
    const memberTypeRef = toRef(filterParams, 'memberType');
    const approvedRef = toRef(filterParams, 'approved');

    watch(
      [isFilterOn, startRef, endRef, memberTypeRef, approvedRef],
      ([isFilterOn]) => {
        // 필터 활성화 상태에서 watch하는 파라미터가 업데이트되면 검색 실행
        if (isFilterOn) {
          filterParams.page = 0;
          executeSearch();
        }
      }
    );

    onMounted(fetchMemberList);

    watch([filterParams, isFilterOn], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchMemberList();
    });

    const handleExcelDownload = _.throttle(async () => {
      try {
        const { data } = await partnerAPI.adminMember.adminMemberExcelList(
            getAppliedFilterParams()
        )
        const list = (data as any).data
        if (list.length === 0) {
          alert('다운로드할 데이터가 없습니다.')
          return
        }
        makeExcel(list)
      } catch (e) {
        console.error(e)
        alert(getServerErrorMessage(e)|| '엑셀 생성 실패')
      }
    }, 10000)

    const makeExcel = (memberList) => {
      const transformed = memberList.map((member, index) => {
        return {
          '가입일': format(new Date(member.member.createdAt), 'yyyy-MM-dd'),
          '회원번호(member#)': member.member.memberId,
          '파트너/일반 구분': member.memberType === 'PARTNER' ? '파트너' : '일반회원',
          '회원명': member.member.name,
          '회원ID(이메일)': member.member.email,
          '휴대전화': member.member.mobile || '',
          '생년월일': member.member.birthday || '',
          '성별': member.member.sex,
          '주소': `${member.member.defaultShippingAddress?.address1 || ''} ${member.member.defaultShippingAddress?.address2 || ''}`,
          '사업자번호': member.partner?.businessNumber,
          '상호': member.partner?.companyName,
          '사업자등록증(URL)': member.partner?.businessLicense,
          '업태': member.partner?.industry,
          '종목': member.partner?.sector,
          '대표자 이름': member.partner?.ceoName,
          '대표자 휴대폰번호': member.partner?.ceoMobile,
          '대표 이메일': member.partner?.csEmail,
          '통신판매업신고번호': member.partner?.onlineSalesBusinessNo,
          '사업자 주소': `${member.partner?.address1 || ''} ${member.partner?.address2 || ''}`,
          '운영담당자': member.partner?.managerName,
          '운영담당자 휴대폰번호': member.partner?.managerMobile,
          '운영담당자 이메일': member.partner?.managerEmail,
          '정산담당자': member.partner?.paymentManagerName,
          '정산담당자 휴대폰번호': member.partner?.paymentManagerMobile,
          '정산담당자 이메일': member.partner?.paymentManagerEmail,
          '입금계좌': `${member.partner?.bankName || ''} ${member.partner?.bankAccount || ''}`,
          '통장사본(URL)': member.partner?.bankbookCopy
        }
      })
      const dataWS = XLSX.utils.json_to_sheet(transformed)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS, '회원목록')
      XLSX.writeFile(wb, `블랙랏회원목록_${format(new Date(), 'yyyyMMddHHmm')}.xlsx`)
    }

    // 가입일 | 회원번호(member#) | 파트너/일반 구분 | 회원명 | 회원ID(이메일) | 휴대전화 | 생년월일 | 성별 | 주소 |
    // 사업자번호 | 상호 | 사업자등록증(URL) | 업태 | 종목 | 대표자 이름 | 대표자 휴대폰번호 | 대표 이메일 | 통신판매업신고번호 | 주소 | 운영담당자 | 운영담당자 휴대폰번호 | 운영담당자 이메일 | 정산담당자 | 정산담당자 휴대폰번호 | 정산담당자 이메일 | 입금계좌 | 통장사본(URL)

    return {
      filterParams,
      memberList,
      toggleApplyFilters,
      handleChangePage,
      handleChangePageSize,
      handleClickSearch,
      executeSearch,
      isFilterOn,
      handleChangeFilterParams,
      fetchMemberList,
      handleExcelDownload
    };
  },
});
</script>

<style lang="scss">
@import "../../../assets/style/label";
.excel-download {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
</style>
